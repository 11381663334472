@import (less) "images/sprite.css";
@import (inline) "css/reset.css";

@roman: 'helveticaneuecyrroman', Arial, Tahoma, sans-serif;
@medium: 'helveticaneuecyrmedium', Arial, Tahoma, sans-serif;
@bold: 'helveticaneuecyrbold', Arial, Tahoma, sans-serif;
@italic: 'helveticaneuecyr-italic', Arial, Tahoma, sans-serif;
@transition: all .3s ease-out;
@blue: #004a92;
@blue_highlight: #00beee;

body{
	font-family: @medium;
	font-size: 14px;
	background: #fff;
}

a{
	text-decoration: none;
	color: inherit;
}  
 
.icon{
	display: inline-block; 
	vertical-align: middle;
} 
.clearfix:after{
	visibility:hidden;
	display:block;
	font-size:0;
	content:" ";
	clear:both;
	height:0;
}

*, 
*:active,  
*:focus{
	outline: none !important;
} 
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}

h2{
	font-size: 24px;
	margin-bottom: 21px;
	color: @blue;
	font-family: @medium;
}

h3{
	font-size: 18px;
	margin-bottom: 14px;
	color: @blue;
	font-family: @medium;
}

.floatLeft{
	float: left;
} 

.floatRight{
	float: right;
}

.page{
	overflow: hidden;
	position: relative;
	display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.overlay{
	position: fixed;
	width: 100vw;
	height: 100vh;
	display: none;
	background: rgba(33,33,33,0.85);
	z-index: 19;
	top: 0;
	left: 0;
}

header{
	padding: 15px 0 16px;
	box-shadow: 0 0 3.9px 0.1px rgba(147, 147, 147, 0.42);
	font-family: @roman;
	position: relative;
}

.header-info{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-left: -10px;
	margin-right: -10px;
	& > div{
		margin: 0 10px;
	}
}

.main-logo a{
	position: relative;
	&:after{
		content: attr(data-title);
		display: block;
		text-align: center;
	}
}

.quote{
	font-size: 14px;
	line-height: 1.286;
	text-align: right;
	margin-left: -15px;
	span{
		display: block;
		text-align: left;
		font-family: @italic;
		line-height: 1.286;
		font-family: @italic;
		padding-right: 28px;
		color: @blue;
	}
}

.header-shop{
	border-radius: 20px;
  	background-color: #f9f9f9;
  	padding: 12px 30px 6px;
	span, a{
		.icon;
		font-size: 18px;
	}
	strong{
		font-family: @medium;
	}
	i{
		margin-right: 22px;
	}
	.points{
		margin-right: 32px;
	}
	& > a{
		margin-bottom: 6px;
	}
}

.not-logged{
	i{
		margin-right: 12px;
	}
	a{
		.icon;
		color: @blue;
		&:hover{
			text-decoration: underline;
		}
	}
}

.logged{
	.avatar{
		float: left;
		width: 73px;
		img{
			display: block;
			width: 100%;
			border-radius: 100%;
			border: 2px solid @blue_highlight;
		}
	}
	.logged-info{
		margin-left: 86px;
	}
	.name{
		color: @blue_highlight;
		font-family: @medium;
		font-size: 18px;
		margin-bottom: 6px;
		margin-top: 4px;
	}
	.cabinet{
		color: @blue;
		margin-bottom: 10px;
		a:hover{
			text-decoration: underline;
		}
	}
	.logout{
		color: #787878;
		i{
			margin-right: 8px;
		}
	}
}

.main-content{
    flex: 1;
}

.main-inner{
	border-bottom: 1px solid #c7e1e7;
	padding: 33px 0 39px;
}

.i-box{
	text-align: center;
	.icon;
}

.sidebar-menu{
	margin-bottom: 25px;
	h2{
		padding-left: 23px;
		margin-bottom: 12px;
	}
	li{
		font-size: 17px;
		margin-bottom: 9px;
		font-family: @roman;
		line-height: 25px;
		&:hover > span{
			color: @blue_highlight;
			&:after{
				opacity: 1;
			}
			i{
				&:extend(.icon-menu_drop_arrow_hover);
			}
		}
	}
	a, span{
		padding-left: 20px;
		color: @blue;
		transition: @transition;
		cursor: pointer;
		position: relative;
		display: inline-block;
		&:after{
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 3px;
			display: block;
			background: @blue_highlight;
			opacity: 0;
			transition: @transition;
		}
		i{
			margin-left: 8px;
		}
	}
	i{
		margin-top: -3px;
	}
	a{
		&:hover{
			color: @blue_highlight;
			&:after{
				opacity: 1;
			}
		}
	}
	ul{
		padding-top: 10px;
		display: none;
		li{
			padding-left: 44px;
			a{
				padding-left: 0;
				border-left: none;
			}
		}
	} 
}

.search-form{
	border-radius: 10px;
  	background-color: #f9f9f9;
  	margin-bottom: 28px;
  	padding: 27px 20px;
  	form{
		width: 100%;
		position: relative;
		height: 40px;
		border-radius: 5px;
  		background-color: #ffffff;
  		border: solid 1px #b1b1b1;
	}
	input{
		height: 100%;
		width: 100%;
		border: none;
		border-radius: 5px;
		padding: 12px 42px 12px 16px;
		text-transform: uppercase;
		font-family: @medium;
		font-size: 14px;
		color: #797979;
	}
	button{
		background-color: transparent;
		border: none;
		cursor: pointer;
		&:extend(.icon-search);
		position: absolute;
		right: 12px;
		top: 10px;
	}
}

.slick-slider{
	margin-bottom: 55px;
	.slider-item{
		height: 340px;
		width: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	.slick-dots{
		text-align: center;
		position: absolute;
		z-index: 10;
		top: 100%;
		width: 100%;
		margin-top: 20px;
		li{
			display: inline-block;
			text-indent: -9999px;
			background: #b7b7b7;
			border: none;
			border-radius: 100%;
			cursor: pointer;
			width: 11px;
			height: 11px;
			margin: 0 4px;
		}
		.slick-active{
			background: @blue_highlight;
		}
	}
	.slick-arrow{
		text-indent: -9999px;
		border: none;
		width: 34px;
		height: 34px;
		z-index: 10;
		position: absolute;
		top: 50%;
		margin-top: -17px;
		border-radius: 100%;
		background-color: transparent;
		cursor: pointer;
		&.slick-prev{
			left: 16px;
			&:extend(.icon-slider_prev);
			&:hover{
				&:extend(.icon-slider_prev_hover);
			}
		}
		&.slick-next{
			right: 16px;
			&:extend(.icon-slider_next);
			&:hover{
				&:extend(.icon-slider_next_hover);
			} 
		}
	}
}

.news-block{
	border-radius: 5px;
  	background-color: #fff;
 	border: solid 1px #c7e1e7;
 	padding: 18px 22px 13px 17px;
 	margin-bottom: 10px;
 	transition: @transition;
 	&:hover{
		background-color: #f9f9f9;
	}
 	.news-img{
		float: left;
		width: 138px;
		img{
			width: 100%;
			border-radius: 4px;
		}
	}
}

.news-block_content{
	margin-left: 157px;
	padding-top: 8px;
	font-family: @roman;
	h3{
		a:hover{
			text-decoration: underline;
		}
	}
	p{
		line-height: 18px;
		margin-bottom: 22px;
	}
	.dop-info{
		a, span, i{
			.icon;
		}
		a{
			color: @blue;
			text-decoration: underline;
			&:hover{
				text-decoration: none;;
			}
		}
		.floatRight{
			margin-top: 3px;
		}
	}
	.date{
		color: #757575;
		font-size: 12px;
		border-radius: 10px;
  		background-color: #f9f9f9;
  		padding: 6px 8px;
		margin-right: 12px;
		font-family: @medium;
	}
	.comment-count{
		margin-right: 12px;
	}
	.view{
		&.viewed i{
			&:extend(.icon-view_active);
		}
	}
}

.events, .birthday{
	margin-bottom: 20px;
	margin-top: -3px;
}

.scroll-container{	
	overflow: hidden;
	overflow-y: auto;
//	padding-right: 6px;
	.mCSB_inside > .mCSB_container{
		margin-right:6px;	
	}
	.mCSB_scrollTools{
		width: 3px;
	}
	.mCSB_draggerRail{
		background: #fff;
	}
	.mCSB_dragger_bar{
		background: @blue_highlight !important;
		width: 3px !important;
	}
}

.events-container{
	height: 316px;
}

.join-block{
	position: relative;
	border-radius: 5px;
  	border: solid 1px #c7e1e7;
  	padding-left: 86px;
  	margin-bottom: 10px;
	.join-img{
		position: absolute;
		z-index: 2;
		left: -1px;
		top: 0;
		height: 100%;
		width: 72px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		border-radius: 5px;
	}
	
}

	.join-info{
		padding: 12px 13px 14px 0;
		display: table;
		width: 100%;
		li{
			display: table-cell;
			vertical-align: middle;
			&:last-child{
				width: 48px;
			}
		}
		p{
			padding-right: 12px;
			line-height: 1.286;
		}
	}

.join-link{
	border-radius: 10px;
	width: 48px;
	height: 29px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
  	border: solid 1px @blue_highlight;
  	transition: @transition;
  	&:hover{
		background: @blue_highlight;
		.icon-gift{
			&:extend(.icon-gift_hover);
		}
		.icon-group{
			&:extend(.icon-group_hover);
		}
	}
}

.birthday-container{
	height: 465px;
	.join-info{
		padding: 15px 13px 17px 0;
		 p{
			font-size: 18px;
			line-height: 1.333;
			font-family: @roman;
		}
	}
}


footer{
	padding: 44px 0 41px;
	h3{
		margin-bottom: 23px;
		margin-top: -3px;
	}
	.floatRight{
		margin-right: 30px; 
	}
	.copyright{
		font-size: 12px;
		color: #b7b7b7;
	}
}

.footer-list{
	li{
		margin-bottom: 14px;
	}
	a, span{
		color: @blue;
		vertical-align: middle;
		&:hover{
			text-decoration: underline;
		}
	}
	.i-box{
		width: 26px;
		margin-right: 14px;
	}
}

.socials{
	padding: 17px 0 30px;
	a{
		display: inline-block;
		margin-right: 2px;
	}
}

.mobile-header{
	display: none;
}

.buter{
	padding: 15px;
	cursor: pointer;
	display: none;
	margin-left: -15px !important;
	span{
		display: block;
		height: 3px;
		background: @blue_highlight;
		margin-bottom: 4px;
		width: 23px;
		&:last-child{
			margin-bottom: 0;
		}
	}
}

.mobile-menu li.open-drop{
	span{
		color: @blue_highlight;
		&:after{
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 3px;
			background: @blue_highlight;
		}
	}
	.icon-menu_drop_arrow{
		&:extend(.icon-menu_drop_arrow_hover);
	}
}

	.mobile-menu-container{
		position: absolute;
		z-index: 20;
		background: #fff;
		left: -300px;
		opacity: 0;
		transition: @transition;
		top: 0;
		width: 228px;
		&:after{
			content: "";
			display: block;
			top: 100%;
			width: 100%;
			background: #fff;
			position: absolute;
			height: 15000px;
		}
		&.open-sidebar{
			left: 0;
			opacity: 1;
		}
		.search-form{
			margin-bottom: 0;
		}
		.back-button{
			cursor: pointer;
			padding: 12px 15px;
			i{
				margin-right: 12px;
			}
			span{
				color: #797979;
				font-family: @medium;
				text-transform: uppercase;
			}
			&:hover{
				.icon-slider_prev{
					&:extend(.icon-slider_prev_hover);
				}
			}
		}
	}

.main-form{
	color: #004b91;
	h3{
		font-size: 30px;
		color: #004a92;
		margin-bottom: 25px;
	}
	input{
		width: 100%;
		margin-bottom: 10px;
		border: 1px solid #d5d5d5;
		border-radius: 5px;
		display: inline-block;
		height: 38px;
		padding: 0 16px;
		color: #004a92;
		font-size: 18px;
		font-family: @medium;
		&::-webkit-input-placeholder { 
			color: #797979;
			font-size: 18px;
		}
		&:-moz-placeholder { 
			color: #797979;
			font-size: 18px;
		}
		&::-moz-placeholder {
			color: #797979;
			font-size: 18px;
		}	
		&:-ms-input-placeholder { 
			color: #797979;
			font-size: 18px;
		}
		& .placeholder { 
			color: #797979;
			font-size: 18px;
		}
		&:focus{
			border-color: #77dcf6;
		}
	}
	input[type="password"]{
		color: #00beee;
		font-size: 25px;
	}
	.radio-box{
		margin-bottom: 28px;
		margin-top: 4px;
	}
	.radio {
	    display: none;
	}
	.radio-custom {
	    width: 12px;
	    height: 12px;
	    border: 1px solid #b6afaf;
	    position: relative;
	}
	.radio-custom,
	.label {
	    display: inline-block;
	    vertical-align: middle;
	    cursor: pointer;
	}
	.radio:checked + .radio-custom::before {
	    content: "";
	    display: block;
	    position: absolute;
	    top: 2px;
	    right: 2px;
	    bottom: 2px;
	    left: 2px;
	    background: #004a92;
	    border-radius: 100%;
	}
	.radio-custom,
	.radio:checked + .radio-custom::before {
	    border-radius: 50%;
	}
	button{
		border:none;
		background: #00beee;
		text-align: center;
		border-radius: 30px;
		color: #fff;
		font-family: @bold;
		font-size: 17px;
		padding: 14px 30px;
		width: 100%;
		margin-bottom: 20px;
		cursor: pointer;
		transition: @transition;
		&:hover{
			background: #004a92;
		}
	}
	.form-phone{
		span{
			display: inline-block;
			vertical-align: middle;
			font-size: 23px;
			margin-right: 6px;
		}
		select{
			width: 80px;
			border: 1px solid #d5d5d5;
			border-radius: 5px;
			display: inline-block;
			vertical-align: middle;
			height: 38px;
			color: #004a92;
			font-size: 18px;
			appearance: none;
			padding: 0 25px 0 8px; 
			text-align: left;
			font-family: @medium;
			line-height: 1;
			background: #fff url("../images/select_arrow.png") right 12px center no-repeat;
			margin-right: 6px;
			option{
				&:first-child{
					display: none;
				}
			}
			&:focus{
				border-color: #77dcf6;
			}
		}
		input{
			width: ~"calc(100% - 141px)";
			vertical-align: top;
		}
	}
}

.recovery{
	font-family: @roman;
	text-align: center;
	a{
		text-decoration: underline;	
		display: inline-block;
		margin-bottom: 10px;
		&:hover{
			text-decoration: none;
		}
	}
}

.alaska-logo{
	display: inline-block;
	padding: 40px 0 34px;
}

.form-outer{
	background: #f9f9f9;
	padding: 32px 0 24px;
	.main-form{
		max-width: 354px;
		button{
			margin-top: 10px;
			img{
				margin-right: 20px;
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	.main-form input[type="password"]{
		font-size: 21px;
	}
}

/*================= МОДАЛКИ ======================*/
#registration {
	max-width: 588px;
}

.popup-modal{
	margin: 30px auto;
	position: relative;
	background: #f8f8f8;
	border-radius: 12px;
	.mfp-close{
		right: 18px;
		height: 26px;
		width: 26px;
    	top: 18px;
    	line-height: 1;
    	opacity: 1 !important;
    	img{
			pointer-events: none;
		}
	}
}

.popup-top{
	padding: 18px 60px 8px 28px;
	border-bottom: 1px solid #c7e1e7;
	text-align: left;
	font-family: @roman;
	a{
		display: inline-block;
		margin-bottom: 10px;
		vertical-align: middle;
		margin-right: 74px;
		color: #00beee;
		transition: @transition;
		font-size: 18px;
		&:hover{
			color: #004a92;
		}
		&:last-child{
			margin-right: 0;
		}
		i{
			margin-right: 10px;
		}
	}
}

.popup-bottom{
	padding: 30px 110px 25px;
	text-align: center;
}

.mfp-bg {
  background: #000; 
}

.mfp-zoom-out { 
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out; 
    transform: scale(1.3); 
  }  
  &.mfp-bg {
    opacity: 0;
	  transition: all 0.3s ease-out;
  } 
  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1); 
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  } 
  /* animate out */
  &.mfp-removing { 
    .mfp-with-anim {
      transform: scale(1.3); 
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }  
  }
}


/*================= MEDIA ======================*/

@media (max-width: 1259px){
	.main-logo img{
		max-width: 140px;	
	}
	.header-shop{
		padding: 12px 30px 6px;
		i{
			margin-right: 10px;
		}
		.points{
			margin-right: 15px;
		}
		a, span{
			font-size: 16px;
		}
	}
	.search-form{
		padding: 15px 10px;
		input{
			font-size: 12px;
		}
		button{
			top: 8px;
		}
	}
	.slick-slider .slider-item{
		height: 260px;
	}
	.join-block .join-img{
		display: none;
	}
	.join-block{
		padding-left: 15px;
	}
	footer .floatRight{
		margin-right: 0;
	}
	.quote{
		display: none;
	}
}

@media (max-width: 1259px) and (min-width: 992px) {
	
}

@media (max-width: 991px){
	h2{
		font-size: 21px;
	}
	h3{
		font-size: 16px;
	}
	.quote, .sidebar {
		display: none;
	}
	.header-shop > a{
		display: block;
	}
	.header-shop{
		padding-left: 30px;
		padding-right: 30px;
	}
	.mobile-menu{
		li{
			border-bottom: 1px solid #c7e1e7;
			&:last-child{
				border-bottom: none;
			}
		}
		a, span{
			display: block;
			padding: 12px 15px;
			color: @blue;
			cursor: pointer;
			transition: @transition;
			position: relative;
			&:hover{
				color: @blue_highlight;
				&:after{
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 3px;
					background: @blue_highlight;
				}
			}
			i{
				float: right;
				margin-right: 3px;
				margin-top: 6px;
			}
		}
		ul{
			padding-left: 15px;
			display: none;
			li{
				border-bottom: none;
			}
			a{
				padding: 8px;
				&:after{
					display: none !important;
				}
			}
		}
	}
	.overlay{
		display: none;
	}
	.buter{
		display: block;
	}
	.main-logo{
		margin-left: -20px !important;
	}
}

@media (max-width: 767px) { 
	.slick-slider{
		display: none;	
	}
	.join-block .join-img{
		display: block;
	}
	.join-block{
		padding-left: 86px;
	}
	footer{
		font-size: 13px;
		.container{
			display: flex;
			justify-content: center;
			flex-direction: row;
		}
		.floatRight, .floatLeft{
			float: none;
		}
	}
	.scroll-container{
		height: auto !important;
	}
	.main-inner{
		padding: 20px 0 15px;
	}
	.header-info{
		display: none;
	}
	.events{
		margin-top: 25px;
	}
	.socials{
		padding: 6px 0 20px;
	}
	.news-block {
		.news-img{
			float: none;
			width: 100%;
			text-align: center;
			margin-bottom: 10px;
			img{
				max-width: 100%;
				width: auto;
				display: inline-block;
			}
		}
	}
	.news-block_content{
		margin-left: 0;
		.date{
			margin-right: 8px;
		} 
	}
	.scroll-container .mCSB_inside > .mCSB_container{
		margin-right: 0;
	} 
	header{
		padding: 0;
	}
	.mobile-header{
		display: block;
		position: relative;
	}
	.mobile-header-top{
		background: @blue;
		padding: 7px 0;
		color: #fff;
		font-size: 13px;
		span, a{
			.icon;
		}
		strong{
			font-family: @medium;
		}
		.catalog{
			float: right;
		}
		i{
			margin-right: 6px;
		}
	}	
	.mobile-header-bottom{
		padding: 10px 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	.log-mobile{
		.avatar{
			width: 38px;
			display: block;
			img{
				display: block;
				width: 100%;
				border-radius: 100%;
				border: 1px solid @blue_highlight;
			}
		}
	}
	.logo-mobile{
		margin-left: -15px;
		position: relative;
		&:after{
			content: attr(data-title);
			display: block;
			text-align: center;
			position: absolute;
			bottom: 8px;
			left: 42px;
			font-size: 12px;
		}
	}
	.popup-bottom{
		padding: 30px 60px 25px;
	}
}

@media (max-width: 480px){
	.join-info p{
		font-size: 12px;
	}
	.birthday-container .join-info p{
		font-size: 15px;
	}
	.news-block .news-img img{
		width: 100%;
	}
	.popup-bottom{
		padding: 30px 30px 25px;
	}
	.popup-top{
		a{
			margin-right: 20px;
		}
	}
	.main-form{
		h3{
			font-size: 25px;
		}
		.radio-box{
			text-align: left;
			label{
				display: block;
				float: none !important;
				margin-bottom: 10px;
			}
		}
	}
	.form-outer .main-form button img{
		display: none;
	}
}
