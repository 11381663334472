/*
Icon classes can be used entirely standalone. They are named after their original file names.

```html
<!-- `display: block` sprite -->
<div class="icon-home"></div>

<!-- `display: inline-block` sprite -->
<img class="icon-home" />
```
*/
.icon-chat {
  background-image: url(../images/sprite.png);
  background-position: -23px -139px;
  width: 19px;
  height: 18px;
}
.icon-credit_card {
  background-image: url(../images/sprite.png);
  background-position: 0px -113px;
  width: 27px;
  height: 21px;
}
.icon-drop {
  background-image: url(../images/sprite.png);
  background-position: -78px -36px;
  width: 31px;
  height: 31px;
}
.icon-drop_mobile {
  background-image: url(../images/sprite.png);
  background-position: -114px 0px;
  width: 26px;
  height: 26px;
}
.icon-facebook {
  background-image: url(../images/sprite.png);
  background-position: -35px -78px;
  width: 30px;
  height: 30px;
}
.icon-gift {
  background-image: url(../images/sprite.png);
  background-position: -69px -139px;
  width: 17px;
  height: 17px;
}
.icon-gift_hover {
  background-image: url(../images/sprite.png);
  background-position: -47px -139px;
  width: 17px;
  height: 17px;
}
.icon-group {
  background-image: url(../images/sprite.png);
  background-position: -32px -113px;
  width: 25px;
  height: 14px;
}
.icon-group_hover {
  background-image: url(../images/sprite.png);
  background-position: -62px -113px;
  width: 25px;
  height: 14px;
}
.icon-heart {
  background-image: url(../images/sprite.png);
  background-position: -145px -84px;
  width: 24px;
  height: 21px;
}
.icon-id_card {
  background-image: url(../images/sprite.png);
  background-position: -114px -31px;
  width: 26px;
  height: 23px;
}
.icon-logout {
  background-image: url(../images/sprite.png);
  background-position: 0px -139px;
  width: 18px;
  height: 19px;
}
.icon-mail {
  background-image: url(../images/sprite.png);
  background-position: -145px -110px;
  width: 23px;
  height: 17px;
}
.icon-menu_drop_arrow {
  background-image: url(../images/sprite.png);
  background-position: -91px -139px;
  width: 10px;
  height: 5px;
}
.icon-menu_drop_arrow_hover {
  background-image: url(../images/sprite.png);
  background-position: -106px -139px;
  width: 10px;
  height: 5px;
}
.icon-phone {
  background-image: url(../images/sprite.png);
  background-position: -145px -56px;
  width: 22px;
  height: 23px;
}
.icon-question {
  background-image: url(../images/sprite.png);
  background-position: -145px 0px;
  width: 24px;
  height: 23px;
}
.icon-review {
  background-image: url(../images/sprite.png);
  background-position: -114px -59px;
  width: 25px;
  height: 23px;
}
.icon-search {
  background-image: url(../images/sprite.png);
  background-position: -114px -87px;
  width: 19px;
  height: 19px;
}
.icon-shopping_cart {
  background-image: url(../images/sprite.png);
  background-position: -78px 0px;
  width: 31px;
  height: 31px;
}
.icon-shopping_cart_mobile {
  background-image: url(../images/sprite.png);
  background-position: -70px -78px;
  width: 26px;
  height: 26px;
}
.icon-skype {
  background-image: url(../images/sprite.png);
  background-position: -145px -28px;
  width: 23px;
  height: 23px;
}
.icon-slider_next {
  background-image: url(../images/sprite.png);
  background-position: 0px 0px;
  width: 34px;
  height: 34px;
}
.icon-slider_next_hover {
  background-image: url(../images/sprite.png);
  background-position: -39px -39px;
  width: 34px;
  height: 34px;
}
.icon-slider_prev {
  background-image: url(../images/sprite.png);
  background-position: 0px -39px;
  width: 34px;
  height: 34px;
}
.icon-slider_prev_hover {
  background-image: url(../images/sprite.png);
  background-position: -39px 0px;
  width: 34px;
  height: 34px;
}
.icon-view {
  background-image: url(../images/sprite.png);
  background-position: -92px -113px;
  width: 20px;
  height: 12px;
}
.icon-view_active {
  background-image: url(../images/sprite.png);
  background-position: -117px -113px;
  width: 20px;
  height: 12px;
}
.icon-youtube {
  background-image: url(../images/sprite.png);
  background-position: 0px -78px;
  width: 30px;
  height: 30px;
}
