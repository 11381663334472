/*
Icon classes can be used entirely standalone. They are named after their original file names.

```html
<!-- `display: block` sprite -->
<div class="icon-home"></div>

<!-- `display: inline-block` sprite -->
<img class="icon-home" />
```
*/
.icon-chat {
  background-image: url(../images/sprite.png);
  background-position: -23px -139px;
  width: 19px;
  height: 18px;
}
.icon-credit_card {
  background-image: url(../images/sprite.png);
  background-position: 0px -113px;
  width: 27px;
  height: 21px;
}
.icon-drop {
  background-image: url(../images/sprite.png);
  background-position: -78px -36px;
  width: 31px;
  height: 31px;
}
.icon-drop_mobile {
  background-image: url(../images/sprite.png);
  background-position: -114px 0px;
  width: 26px;
  height: 26px;
}
.icon-facebook {
  background-image: url(../images/sprite.png);
  background-position: -35px -78px;
  width: 30px;
  height: 30px;
}
.icon-gift {
  background-image: url(../images/sprite.png);
  background-position: -69px -139px;
  width: 17px;
  height: 17px;
}
.icon-gift_hover,
.join-link:hover .icon-gift {
  background-image: url(../images/sprite.png);
  background-position: -47px -139px;
  width: 17px;
  height: 17px;
}
.icon-group {
  background-image: url(../images/sprite.png);
  background-position: -32px -113px;
  width: 25px;
  height: 14px;
}
.icon-group_hover,
.join-link:hover .icon-group {
  background-image: url(../images/sprite.png);
  background-position: -62px -113px;
  width: 25px;
  height: 14px;
}
.icon-heart {
  background-image: url(../images/sprite.png);
  background-position: -145px -84px;
  width: 24px;
  height: 21px;
}
.icon-id_card {
  background-image: url(../images/sprite.png);
  background-position: -114px -31px;
  width: 26px;
  height: 23px;
}
.icon-logout {
  background-image: url(../images/sprite.png);
  background-position: 0px -139px;
  width: 18px;
  height: 19px;
}
.icon-mail {
  background-image: url(../images/sprite.png);
  background-position: -145px -110px;
  width: 23px;
  height: 17px;
}
.icon-menu_drop_arrow {
  background-image: url(../images/sprite.png);
  background-position: -91px -139px;
  width: 10px;
  height: 5px;
}
.icon-menu_drop_arrow_hover,
.sidebar-menu li:hover > span i,
.mobile-menu li.open-drop .icon-menu_drop_arrow {
  background-image: url(../images/sprite.png);
  background-position: -106px -139px;
  width: 10px;
  height: 5px;
}
.icon-phone {
  background-image: url(../images/sprite.png);
  background-position: -145px -56px;
  width: 22px;
  height: 23px;
}
.icon-question {
  background-image: url(../images/sprite.png);
  background-position: -145px 0px;
  width: 24px;
  height: 23px;
}
.icon-review {
  background-image: url(../images/sprite.png);
  background-position: -114px -59px;
  width: 25px;
  height: 23px;
}
.icon-search,
.search-form button {
  background-image: url(../images/sprite.png);
  background-position: -114px -87px;
  width: 19px;
  height: 19px;
}
.icon-shopping_cart {
  background-image: url(../images/sprite.png);
  background-position: -78px 0px;
  width: 31px;
  height: 31px;
}
.icon-shopping_cart_mobile {
  background-image: url(../images/sprite.png);
  background-position: -70px -78px;
  width: 26px;
  height: 26px;
}
.icon-skype {
  background-image: url(../images/sprite.png);
  background-position: -145px -28px;
  width: 23px;
  height: 23px;
}
.icon-slider_next,
.slick-slider .slick-arrow.slick-next {
  background-image: url(../images/sprite.png);
  background-position: 0px 0px;
  width: 34px;
  height: 34px;
}
.icon-slider_next_hover,
.slick-slider .slick-arrow.slick-next:hover {
  background-image: url(../images/sprite.png);
  background-position: -39px -39px;
  width: 34px;
  height: 34px;
}
.icon-slider_prev,
.slick-slider .slick-arrow.slick-prev {
  background-image: url(../images/sprite.png);
  background-position: 0px -39px;
  width: 34px;
  height: 34px;
}
.icon-slider_prev_hover,
.slick-slider .slick-arrow.slick-prev:hover,
.mobile-menu-container .back-button:hover .icon-slider_prev {
  background-image: url(../images/sprite.png);
  background-position: -39px 0px;
  width: 34px;
  height: 34px;
}
.icon-view {
  background-image: url(../images/sprite.png);
  background-position: -92px -113px;
  width: 20px;
  height: 12px;
}
.icon-view_active,
.news-block_content .view.viewed i {
  background-image: url(../images/sprite.png);
  background-position: -117px -113px;
  width: 20px;
  height: 12px;
}
.icon-youtube {
  background-image: url(../images/sprite.png);
  background-position: 0px -78px;
  width: 30px;
  height: 30px;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
body {
  font-family: 'helveticaneuecyrmedium', Arial, Tahoma, sans-serif;
  font-size: 14px;
  background: #fff;
}
a {
  text-decoration: none;
  color: inherit;
}
.icon {
  display: inline-block;
  vertical-align: middle;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
*,
*:active,
*:focus {
  outline: none !important;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
h2 {
  font-size: 24px;
  margin-bottom: 21px;
  color: #004a92;
  font-family: 'helveticaneuecyrmedium', Arial, Tahoma, sans-serif;
}
h3 {
  font-size: 18px;
  margin-bottom: 14px;
  color: #004a92;
  font-family: 'helveticaneuecyrmedium', Arial, Tahoma, sans-serif;
}
.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
.page {
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: none;
  background: rgba(33, 33, 33, 0.85);
  z-index: 19;
  top: 0;
  left: 0;
}
header {
  padding: 15px 0 16px;
  box-shadow: 0 0 3.9px 0.1px rgba(147, 147, 147, 0.42);
  font-family: 'helveticaneuecyrroman', Arial, Tahoma, sans-serif;
  position: relative;
}
.header-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;
}
.header-info > div {
  margin: 0 10px;
}
.main-logo a {
  position: relative;
}
.main-logo a:after {
  content: attr(data-title);
  display: block;
  text-align: center;
}
.quote {
  font-size: 14px;
  line-height: 1.286;
  text-align: right;
  margin-left: -15px;
}
.quote span {
  display: block;
  text-align: left;
  line-height: 1.286;
  font-family: 'helveticaneuecyr-italic', Arial, Tahoma, sans-serif;
  padding-right: 28px;
  color: #004a92;
}
.header-shop {
  border-radius: 20px;
  background-color: #f9f9f9;
  padding: 12px 30px 6px;
}
.header-shop span,
.header-shop a {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
}
.header-shop strong {
  font-family: 'helveticaneuecyrmedium', Arial, Tahoma, sans-serif;
}
.header-shop i {
  margin-right: 22px;
}
.header-shop .points {
  margin-right: 32px;
}
.header-shop > a {
  margin-bottom: 6px;
}
.not-logged i {
  margin-right: 12px;
}
.not-logged a {
  display: inline-block;
  vertical-align: middle;
  color: #004a92;
}
.not-logged a:hover {
  text-decoration: underline;
}
.logged .avatar {
  float: left;
  width: 73px;
}
.logged .avatar img {
  display: block;
  width: 100%;
  border-radius: 100%;
  border: 2px solid #00beee;
}
.logged .logged-info {
  margin-left: 86px;
}
.logged .name {
  color: #00beee;
  font-family: 'helveticaneuecyrmedium', Arial, Tahoma, sans-serif;
  font-size: 18px;
  margin-bottom: 6px;
  margin-top: 4px;
}
.logged .cabinet {
  color: #004a92;
  margin-bottom: 10px;
}
.logged .cabinet a:hover {
  text-decoration: underline;
}
.logged .logout {
  color: #787878;
}
.logged .logout i {
  margin-right: 8px;
}
.main-content {
  flex: 1;
}
.main-inner {
  border-bottom: 1px solid #c7e1e7;
  padding: 33px 0 39px;
}
.i-box {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}
.sidebar-menu {
  margin-bottom: 25px;
}
.sidebar-menu h2 {
  padding-left: 23px;
  margin-bottom: 12px;
}
.sidebar-menu li {
  font-size: 17px;
  margin-bottom: 9px;
  font-family: 'helveticaneuecyrroman', Arial, Tahoma, sans-serif;
  line-height: 25px;
}
.sidebar-menu li:hover > span {
  color: #00beee;
}
.sidebar-menu li:hover > span:after {
  opacity: 1;
}
.sidebar-menu a,
.sidebar-menu span {
  padding-left: 20px;
  color: #004a92;
  transition: all 0.3s ease-out;
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.sidebar-menu a:after,
.sidebar-menu span:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  display: block;
  background: #00beee;
  opacity: 0;
  transition: all 0.3s ease-out;
}
.sidebar-menu a i,
.sidebar-menu span i {
  margin-left: 8px;
}
.sidebar-menu i {
  margin-top: -3px;
}
.sidebar-menu a:hover {
  color: #00beee;
}
.sidebar-menu a:hover:after {
  opacity: 1;
}
.sidebar-menu ul {
  padding-top: 10px;
  display: none;
}
.sidebar-menu ul li {
  padding-left: 44px;
}
.sidebar-menu ul li a {
  padding-left: 0;
  border-left: none;
}
.search-form {
  border-radius: 10px;
  background-color: #f9f9f9;
  margin-bottom: 28px;
  padding: 27px 20px;
}
.search-form form {
  width: 100%;
  position: relative;
  height: 40px;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #b1b1b1;
}
.search-form input {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 12px 42px 12px 16px;
  text-transform: uppercase;
  font-family: 'helveticaneuecyrmedium', Arial, Tahoma, sans-serif;
  font-size: 14px;
  color: #797979;
}
.search-form button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 10px;
}
.slick-slider {
  margin-bottom: 55px;
}
.slick-slider .slider-item {
  height: 340px;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.slick-slider .slick-dots {
  text-align: center;
  position: absolute;
  z-index: 10;
  top: 100%;
  width: 100%;
  margin-top: 20px;
}
.slick-slider .slick-dots li {
  display: inline-block;
  text-indent: -9999px;
  background: #b7b7b7;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  width: 11px;
  height: 11px;
  margin: 0 4px;
}
.slick-slider .slick-dots .slick-active {
  background: #00beee;
}
.slick-slider .slick-arrow {
  text-indent: -9999px;
  border: none;
  width: 34px;
  height: 34px;
  z-index: 10;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  border-radius: 100%;
  background-color: transparent;
  cursor: pointer;
}
.slick-slider .slick-arrow.slick-prev {
  left: 16px;
}
.slick-slider .slick-arrow.slick-next {
  right: 16px;
}
.news-block {
  border-radius: 5px;
  background-color: #fff;
  border: solid 1px #c7e1e7;
  padding: 18px 22px 13px 17px;
  margin-bottom: 10px;
  transition: all 0.3s ease-out;
}
.news-block:hover {
  background-color: #f9f9f9;
}
.news-block .news-img {
  float: left;
  width: 138px;
}
.news-block .news-img img {
  width: 100%;
  border-radius: 4px;
}
.news-block_content {
  margin-left: 157px;
  padding-top: 8px;
  font-family: 'helveticaneuecyrroman', Arial, Tahoma, sans-serif;
}
.news-block_content h3 a:hover {
  text-decoration: underline;
}
.news-block_content p {
  line-height: 18px;
  margin-bottom: 22px;
}
.news-block_content .dop-info a,
.news-block_content .dop-info span,
.news-block_content .dop-info i {
  display: inline-block;
  vertical-align: middle;
}
.news-block_content .dop-info a {
  color: #004a92;
  text-decoration: underline;
}
.news-block_content .dop-info a:hover {
  text-decoration: none;
}
.news-block_content .dop-info .floatRight {
  margin-top: 3px;
}
.news-block_content .date {
  color: #757575;
  font-size: 12px;
  border-radius: 10px;
  background-color: #f9f9f9;
  padding: 6px 8px;
  margin-right: 12px;
  font-family: 'helveticaneuecyrmedium', Arial, Tahoma, sans-serif;
}
.news-block_content .comment-count {
  margin-right: 12px;
}
.events,
.birthday {
  margin-bottom: 20px;
  margin-top: -3px;
}
.scroll-container {
  overflow: hidden;
  overflow-y: auto;
}
.scroll-container .mCSB_inside > .mCSB_container {
  margin-right: 6px;
}
.scroll-container .mCSB_scrollTools {
  width: 3px;
}
.scroll-container .mCSB_draggerRail {
  background: #fff;
}
.scroll-container .mCSB_dragger_bar {
  background: #00beee !important;
  width: 3px !important;
}
.events-container {
  height: 316px;
}
.join-block {
  position: relative;
  border-radius: 5px;
  border: solid 1px #c7e1e7;
  padding-left: 86px;
  margin-bottom: 10px;
}
.join-block .join-img {
  position: absolute;
  z-index: 2;
  left: -1px;
  top: 0;
  height: 100%;
  width: 72px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}
.join-info {
  padding: 12px 13px 14px 0;
  display: table;
  width: 100%;
}
.join-info li {
  display: table-cell;
  vertical-align: middle;
}
.join-info li:last-child {
  width: 48px;
}
.join-info p {
  padding-right: 12px;
  line-height: 1.286;
}
.join-link {
  border-radius: 10px;
  width: 48px;
  height: 29px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: solid 1px #00beee;
  transition: all 0.3s ease-out;
}
.join-link:hover {
  background: #00beee;
}
.birthday-container {
  height: 465px;
}
.birthday-container .join-info {
  padding: 15px 13px 17px 0;
}
.birthday-container .join-info p {
  font-size: 18px;
  line-height: 1.333;
  font-family: 'helveticaneuecyrroman', Arial, Tahoma, sans-serif;
}
footer {
  padding: 44px 0 41px;
}
footer h3 {
  margin-bottom: 23px;
  margin-top: -3px;
}
footer .floatRight {
  margin-right: 30px;
}
footer .copyright {
  font-size: 12px;
  color: #b7b7b7;
}
.footer-list li {
  margin-bottom: 14px;
}
.footer-list a,
.footer-list span {
  color: #004a92;
  vertical-align: middle;
}
.footer-list a:hover,
.footer-list span:hover {
  text-decoration: underline;
}
.footer-list .i-box {
  width: 26px;
  margin-right: 14px;
}
.socials {
  padding: 17px 0 30px;
}
.socials a {
  display: inline-block;
  margin-right: 2px;
}
.mobile-header {
  display: none;
}
.buter {
  padding: 15px;
  cursor: pointer;
  display: none;
  margin-left: -15px !important;
}
.buter span {
  display: block;
  height: 3px;
  background: #00beee;
  margin-bottom: 4px;
  width: 23px;
}
.buter span:last-child {
  margin-bottom: 0;
}
.mobile-menu li.open-drop span {
  color: #00beee;
}
.mobile-menu li.open-drop span:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: #00beee;
}
.mobile-menu-container {
  position: absolute;
  z-index: 20;
  background: #fff;
  left: -300px;
  opacity: 0;
  transition: all 0.3s ease-out;
  top: 0;
  width: 228px;
}
.mobile-menu-container:after {
  content: "";
  display: block;
  top: 100%;
  width: 100%;
  background: #fff;
  position: absolute;
  height: 15000px;
}
.mobile-menu-container.open-sidebar {
  left: 0;
  opacity: 1;
}
.mobile-menu-container .search-form {
  margin-bottom: 0;
}
.mobile-menu-container .back-button {
  cursor: pointer;
  padding: 12px 15px;
}
.mobile-menu-container .back-button i {
  margin-right: 12px;
}
.mobile-menu-container .back-button span {
  color: #797979;
  font-family: 'helveticaneuecyrmedium', Arial, Tahoma, sans-serif;
  text-transform: uppercase;
}
.main-form {
  color: #004b91;
}
.main-form h3 {
  font-size: 30px;
  color: #004a92;
  margin-bottom: 25px;
}
.main-form input {
  width: 100%;
  margin-bottom: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  display: inline-block;
  height: 38px;
  padding: 0 16px;
  color: #004a92;
  font-size: 18px;
  font-family: 'helveticaneuecyrmedium', Arial, Tahoma, sans-serif;
}
.main-form input::-webkit-input-placeholder {
  color: #797979;
  font-size: 18px;
}
.main-form input:-moz-placeholder {
  color: #797979;
  font-size: 18px;
}
.main-form input::-moz-placeholder {
  color: #797979;
  font-size: 18px;
}
.main-form input:-ms-input-placeholder {
  color: #797979;
  font-size: 18px;
}
.main-form input .placeholder {
  color: #797979;
  font-size: 18px;
}
.main-form input:focus {
  border-color: #77dcf6;
}
.main-form input[type="password"] {
  color: #00beee;
  font-size: 25px;
}
.main-form .radio-box {
  margin-bottom: 28px;
  margin-top: 4px;
}
.main-form .radio {
  display: none;
}
.main-form .radio-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #b6afaf;
  position: relative;
}
.main-form .radio-custom,
.main-form .label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}
.main-form .radio:checked + .radio-custom::before {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  background: #004a92;
  border-radius: 100%;
}
.main-form .radio-custom,
.main-form .radio:checked + .radio-custom::before {
  border-radius: 50%;
}
.main-form button {
  border: none;
  background: #00beee;
  text-align: center;
  border-radius: 30px;
  color: #fff;
  font-family: 'helveticaneuecyrbold', Arial, Tahoma, sans-serif;
  font-size: 17px;
  padding: 14px 30px;
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.main-form button:hover {
  background: #004a92;
}
.main-form .form-phone span {
  display: inline-block;
  vertical-align: middle;
  font-size: 23px;
  margin-right: 6px;
}
.main-form .form-phone select {
  width: 80px;
  border: 1px solid #d5d5d5;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  height: 38px;
  color: #004a92;
  font-size: 18px;
  appearance: none;
  padding: 0 25px 0 8px;
  text-align: left;
  font-family: 'helveticaneuecyrmedium', Arial, Tahoma, sans-serif;
  line-height: 1;
  background: #fff url("../images/select_arrow.png") right 12px center no-repeat;
  margin-right: 6px;
}
.main-form .form-phone select option:first-child {
  display: none;
}
.main-form .form-phone select:focus {
  border-color: #77dcf6;
}
.main-form .form-phone input {
  width: calc(100% - 141px);
  vertical-align: top;
}
.recovery {
  font-family: 'helveticaneuecyrroman', Arial, Tahoma, sans-serif;
  text-align: center;
}
.recovery a {
  text-decoration: underline;
  display: inline-block;
  margin-bottom: 10px;
}
.recovery a:hover {
  text-decoration: none;
}
.alaska-logo {
  display: inline-block;
  padding: 40px 0 34px;
}
.form-outer {
  background: #f9f9f9;
  padding: 32px 0 24px;
}
.form-outer .main-form {
  max-width: 354px;
}
.form-outer .main-form button {
  margin-top: 10px;
}
.form-outer .main-form button img {
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .main-form input[type="password"] {
    font-size: 21px;
  }
}
/*================= МОДАЛКИ ======================*/
#registration {
  max-width: 588px;
}
.popup-modal {
  margin: 30px auto;
  position: relative;
  background: #f8f8f8;
  border-radius: 12px;
}
.popup-modal .mfp-close {
  right: 18px;
  height: 26px;
  width: 26px;
  top: 18px;
  line-height: 1;
  opacity: 1 !important;
}
.popup-modal .mfp-close img {
  pointer-events: none;
}
.popup-top {
  padding: 18px 60px 8px 28px;
  border-bottom: 1px solid #c7e1e7;
  text-align: left;
  font-family: 'helveticaneuecyrroman', Arial, Tahoma, sans-serif;
}
.popup-top a {
  display: inline-block;
  margin-bottom: 10px;
  vertical-align: middle;
  margin-right: 74px;
  color: #00beee;
  transition: all 0.3s ease-out;
  font-size: 18px;
}
.popup-top a:hover {
  color: #004a92;
}
.popup-top a:last-child {
  margin-right: 0;
}
.popup-top a i {
  margin-right: 10px;
}
.popup-bottom {
  padding: 30px 110px 25px;
  text-align: center;
}
.mfp-bg {
  background: #000;
}
.mfp-zoom-out {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-out .mfp-with-anim {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(1.3);
}
.mfp-zoom-out.mfp-bg {
  opacity: 0;
  transition: all 0.3s ease-out;
}
.mfp-zoom-out.mfp-ready .mfp-with-anim {
  opacity: 1;
  transform: scale(1);
}
.mfp-zoom-out.mfp-ready.mfp-bg {
  opacity: 0.8;
}
.mfp-zoom-out.mfp-removing .mfp-with-anim {
  transform: scale(1.3);
  opacity: 0;
}
.mfp-zoom-out.mfp-removing.mfp-bg {
  opacity: 0;
}
/*================= MEDIA ======================*/
@media (max-width: 1259px) {
  .main-logo img {
    max-width: 140px;
  }
  .header-shop {
    padding: 12px 30px 6px;
  }
  .header-shop i {
    margin-right: 10px;
  }
  .header-shop .points {
    margin-right: 15px;
  }
  .header-shop a,
  .header-shop span {
    font-size: 16px;
  }
  .search-form {
    padding: 15px 10px;
  }
  .search-form input {
    font-size: 12px;
  }
  .search-form button {
    top: 8px;
  }
  .slick-slider .slider-item {
    height: 260px;
  }
  .join-block .join-img {
    display: none;
  }
  .join-block {
    padding-left: 15px;
  }
  footer .floatRight {
    margin-right: 0;
  }
  .quote {
    display: none;
  }
}
@media (max-width: 991px) {
  h2 {
    font-size: 21px;
  }
  h3 {
    font-size: 16px;
  }
  .quote,
  .sidebar {
    display: none;
  }
  .header-shop > a {
    display: block;
  }
  .header-shop {
    padding-left: 30px;
    padding-right: 30px;
  }
  .mobile-menu li {
    border-bottom: 1px solid #c7e1e7;
  }
  .mobile-menu li:last-child {
    border-bottom: none;
  }
  .mobile-menu a,
  .mobile-menu span {
    display: block;
    padding: 12px 15px;
    color: #004a92;
    cursor: pointer;
    transition: all 0.3s ease-out;
    position: relative;
  }
  .mobile-menu a:hover,
  .mobile-menu span:hover {
    color: #00beee;
  }
  .mobile-menu a:hover:after,
  .mobile-menu span:hover:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background: #00beee;
  }
  .mobile-menu a i,
  .mobile-menu span i {
    float: right;
    margin-right: 3px;
    margin-top: 6px;
  }
  .mobile-menu ul {
    padding-left: 15px;
    display: none;
  }
  .mobile-menu ul li {
    border-bottom: none;
  }
  .mobile-menu ul a {
    padding: 8px;
  }
  .mobile-menu ul a:after {
    display: none !important;
  }
  .overlay {
    display: none;
  }
  .buter {
    display: block;
  }
  .main-logo {
    margin-left: -20px !important;
  }
}
@media (max-width: 767px) {
  .slick-slider {
    display: none;
  }
  .join-block .join-img {
    display: block;
  }
  .join-block {
    padding-left: 86px;
  }
  footer {
    font-size: 13px;
  }
  footer .container {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  footer .floatRight,
  footer .floatLeft {
    float: none;
  }
  .scroll-container {
    height: auto !important;
  }
  .main-inner {
    padding: 20px 0 15px;
  }
  .header-info {
    display: none;
  }
  .events {
    margin-top: 25px;
  }
  .socials {
    padding: 6px 0 20px;
  }
  .news-block .news-img {
    float: none;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .news-block .news-img img {
    max-width: 100%;
    width: auto;
    display: inline-block;
  }
  .news-block_content {
    margin-left: 0;
  }
  .news-block_content .date {
    margin-right: 8px;
  }
  .scroll-container .mCSB_inside > .mCSB_container {
    margin-right: 0;
  }
  header {
    padding: 0;
  }
  .mobile-header {
    display: block;
    position: relative;
  }
  .mobile-header-top {
    background: #004a92;
    padding: 7px 0;
    color: #fff;
    font-size: 13px;
  }
  .mobile-header-top span,
  .mobile-header-top a {
    display: inline-block;
    vertical-align: middle;
  }
  .mobile-header-top strong {
    font-family: 'helveticaneuecyrmedium', Arial, Tahoma, sans-serif;
  }
  .mobile-header-top .catalog {
    float: right;
  }
  .mobile-header-top i {
    margin-right: 6px;
  }
  .mobile-header-bottom {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .log-mobile .avatar {
    width: 38px;
    display: block;
  }
  .log-mobile .avatar img {
    display: block;
    width: 100%;
    border-radius: 100%;
    border: 1px solid #00beee;
  }
  .logo-mobile {
    margin-left: -15px;
    position: relative;
  }
  .logo-mobile:after {
    content: attr(data-title);
    display: block;
    text-align: center;
    position: absolute;
    bottom: 8px;
    left: 42px;
    font-size: 12px;
  }
  .popup-bottom {
    padding: 30px 60px 25px;
  }
}
@media (max-width: 480px) {
  .join-info p {
    font-size: 12px;
  }
  .birthday-container .join-info p {
    font-size: 15px;
  }
  .news-block .news-img img {
    width: 100%;
  }
  .popup-bottom {
    padding: 30px 30px 25px;
  }
  .popup-top a {
    margin-right: 20px;
  }
  .main-form h3 {
    font-size: 25px;
  }
  .main-form .radio-box {
    text-align: left;
  }
  .main-form .radio-box label {
    display: block;
    float: none !important;
    margin-bottom: 10px;
  }
  .form-outer .main-form button img {
    display: none;
  }
}
